.userSection {
	width: 100%;
	min-height: 80px;
	display: flex;
	margin-bottom: 15px;
}

.userAvatar {
	align-self: center;
	height: 80px;
	width: 80px;
	border-radius: 40px;
	object-fit: contain;
}

.userDetail {
	height: 100%;
	width: 100%;
	padding-top: 10px;
	margin-left: 20px;
}

.userHeadingContainer {
	display: flex;
	align-items: center;
}
.userHeading {
	font-family: sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.1px;
	color: #1b1f24;
	margin-right: 10px;
}

.userHeadingContainer > span {
	font-weight: 500;
	font-size: 10px;
	color: #1b1f24;
}

.locationText {
	margin-top: 10px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.1px;
	mix-blend-mode: normal;
	margin-bottom: auto;
	color: #2f80ed;
}

.locationText a {
	text-decoration: none;
	cursor: pointer;
	color: inherit;
}

.locationText a:hover {
	color: #2f81eddc;
	text-decoration: underline;
}

.userOption {
	height: 40px;
	width: 60px;
	border: none;
	background-color: transparent;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.optionSvg {
	align-self: center;
}

.optionModal {
	position: absolute;
	top: 0px;
	right: 15px;
	width: 150px;
	height: 60px;
	background-color: #fff;
	box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.1);
	border: none;
	cursor: pointer;
	outline: none;
	list-style: none;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
}

.optionModal li {
	font-weight: bold;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.075px;
	color: #1b1f24;
	transition: all 0.1s ease-in-out;
}

.optionModal li:hover {
	color: #1b1f24bb;
}
.textDecorationNone {
	text-decoration: none;
}
