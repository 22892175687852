.overlay {
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.8);
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.card {
	width: 484px;
	min-height: 430px;
	background-color: #fff;
	padding: 10px;
	border-radius: 20px;
	overflow: hidden;
}

.button {
	color: #000;
}

@media only screen and (max-width: 600px) {
	.button {
		width: 126px;
	}
  }

.fileInput::-webkit-file-upload-button {
	padding: 10px;
	background-color: #f7b500;
	border: none;
	border-radius: 10px;
	color: #eee;
	cursor: pointer;
}

.fileInput::-webkit-file-upload-button:hover {
	box-shadow: 1px 3px 1px rgba(0, 0, 0, 0.3);
}

.bottomModal {
	display: flex;
	align-items: center;
}

.headingCenter {
	display: flex;
	align-items: center;
}

.modalCross
{
	float: right;
    padding: 5px;
    text-decoration: none;
    color: #665a5a;
    font-weight: 600;
}


.modalTitle
{
	float: left;
}

.month
{
	width: 49%;
    float: left;
}


.year
{
	width: 49%;
    float: right;
}

.dateSelect
{
	width: 100%;
    background-color: white;
    border-radius: 5px;
	height: 42px;
	padding-left: 15px;
}

.marginBottom16
{
	margin-bottom: 16px;
}

.label {
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.075px;
	color: #000;
}