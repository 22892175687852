.reactionContainer {
	margin-top: 15px;
	width: 95%;
	margin: 0 auto;
	height: 35px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
	padding-bottom: 8px;
	border-bottom: 1px solid #979797;
}

.likeContainer {
	display: flex;
	align-items: center;
	justify-content: center;
}
.reactionTextContainer {
	display: flex;
}

.reactionText {
	font-family: Montserrat;
	font-style: normal;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.075px;
	color: #1b1f24;
	margin: 0px 10px;
}
