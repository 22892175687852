.storyHeading {
	font-family: Montserrat;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	line-height: 29px;
	letter-spacing: 0.15px;
	overflow-wrap: break-word;

	color: #1b1f24;
}

.storyDetail {
	margin-top: 10px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 20px;
	letter-spacing: 0.075px;
	color: #1b1f24;
	mix-blend-mode: normal;
	overflow-wrap: break-word;

	opacity: 0.5;
}
