@import url(https://fonts.googleapis.com/css?family=Montserrat:regular,bold,italic&subset=latin,latin-ext);
.Button_primaryBtn__1AC4Z {
	background: #f7b500;
	border-radius: 10px;
	color: white;
	width: 206px;
	height: 48px;
	border: none;
	margin-left: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	overflow: hidden;
}

.Button_primaryBtn__1AC4Z:focus,
.Button_primaryBtn__1AC4Z:active {
	border: none;
	outline: none;
}

/* Button with outline */
.Button_primaryOutline__2ShqC {
	border-radius: 10px;
	height: 57px;
	font-size: 16px;
	width: 154px;
	border: 1px solid #f7b500;
	background-color: #ffffff;
	cursor: pointer;
}

.Button_primaryOutline__2ShqC:hover {
	outline: none;
	background-color: #f7b500;
	border: 1px solid #ffffff;
	color: #ffffff;
}

.Button_spinner__8K6fs {
	width: 48px;
	height: 48px;
}

h1,
h2,
h3,
h4,
h5 {
	font-family: 'SF Pro Display';
	font-style: normal;
	font-weight: bold;
	/* line-height: 48px; */
	letter-spacing: 0.25px;
	color: #1b1f24;
}

h1 {
	font-size: 40px;
}
h2 {
	font-size: 24px;
}
p {
	font-size: 14px;
}

.Font_primaryPara__rgZPq {
	color: var(--color-yellow);
}

.Font_error__3FnX_ {
	font-size: 12px;
	color: red;
}

.Input_inputGroup__2FwsT {
	display: flex;
	flex-direction: column;
	margin-bottom: 24px;
	position: relative;
}

.Input_label__3YoAc {
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.075px;
	color: #000;
}

.Input_errorText__1KUaj {
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.075px;
	color: red;
	margin-top: 5px;
}

.Input_formControl__2QImI {
	border: 1px solid #979797;
	border-radius: 10px;
	margin-top: 7px;
	height: 48px;
	padding: 0 20px;
}

.Input_formControl__2QImI:focus {
	outline: none;
}

.Input_eyeIcon__1Zhmc {
	position: absolute;
	right: 10px;
	top: 70%;
	-webkit-transform: translateY(-70%);
	        transform: translateY(-70%);
	width: 20px;
	height: 20px;
}

.BackgroundImage_container__19ytD {
	width: 100%;
	height: 100%;
	padding: 130px 120px;
	overflow: shidden;
	position: relative;
}

.BackgroundImage_mainImage__3xpCj {
	object-fit: fill;
	width: 100%;
}

.BackgroundImage_logoImage__4iYam {
	position: absolute;
	left: 40px;
	top: 40px;
	height: 52;
	width: 150px;
	z-index: 100;
}

.FullPageSpinner_container__1Jtj3 {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.FullPageSpinner_spinner__vy2yX {
	width: 300px;
	height: 300px;
}

.Sidebar_logoContainer__3551k {
	margin: 27px 0 0 40px;
}

.Sidebar_navLinks__2a5X9 {
	margin-top: 60px;
}

.Sidebar_navLinks__2a5X9 > li {
	display: flex;
	align-items: center;
	padding: 20px 0 20px 20px;
}

.Sidebar_navLinks__2a5X9 > li:hover {
	background: #f3f3f3;
	cursor: pointer;
}

.Sidebar_navLinks__2a5X9 a {
	text-decoration: none;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.1px;
	color: #1b1f24;
	margin-left: 16px;
}

.Sidebar_active__YxoaX {
	background-color: #f3f3f3;
}

.Search_wrapperClass__1y7k6 {
	display: flex;
	justify-content: center;
	height: 100px;
	max-width: 1225px;
	margin-left: auto;
	margin-right: auto;
}

.Search_searchWrapper__2ONsM {
	display: flex;
	height: 80%;
	width: 94%;
	margin-left: 5px;
	align-items: center;
	margin-bottom: auto;
	box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.1);
}

.Search_icon__5YzcR {
	background: none;
	border: none;
	outline: none;
	height: 32px;
	width: 32px;
}

.Search_icon__5YzcR:hover {
	cursor: pointer;
}

.Search_avatarButton__3Gstu {
	width: 60px;
	height: 60px;
	background: transparent;
	margin: auto 45px 32px 15px;
	outline: none;
	border: none;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Search_avatar__3xUMz {
	width: 50px;
	height: 50px;
	border-radius: 25px;
}

.Search_menu__1JfEE {
	position: fixed;
	top: 65px;
	right: 20px;
	width: 150px;
	z-index: 100;
	display: flex;
	flex-direction: column;
	background-color: #fff;
	box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.1);
	padding: 10px;
}

@media only screen and (max-width: 767px) {
	.Search_menu__1JfEE {
		top: 126px;
	}
}

@media only screen and (max-width: 1350px) {
	.Search_avatarButton__3Gstu {
		margin: auto auto 32px 15px;
	}
}
.Search_menu__1JfEE button {
	width: 100%;
	height: 30px;
	background-color: transparent;
	border: none;
	text-align: left;

	font-weight: bold;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.075px;
	color: #1b1f24;
	cursor: pointer;
}

.Search_menu__1JfEE button:hover {
	color: #ccc;
}

.Search_inputSearch__3Ovfp {
	width: 100%;
	height: 100%;
	border: none;
	font-size: 24px;
	color: #1b1f24;
	opacity: 0.25;
	padding-left: 15px;
}
.Search_inputSearch__3Ovfp:focus {
	outline: none;
}

.Search_form__xlyUu {
	width: 98%;
	margin: 0 auto;
}

.Search_formTop__3PYyo {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
}

.Search_formTop__3PYyo > button {
	background-color: transparent;
	font-size: 25px;
	border: none;
	cursor: pointer;
}

.Modal_overlay__1b9pj {
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.8);
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.Modal_card__3mrws {
	width: 484px;
	min-height: 326px;
	background-color: #fff;
	padding: 10px;
	border-radius: 20px;
}

.UserIcon_wrapperClass__2Z05i {
	height: 100px;
	max-width: 1225px;
	/* margin-left: auto;
	margin-right: auto; */
	float: right;
}


.UserIcon_icon__3Kn8X {
	background: none;
	border: none;
	outline: none;
	height: 32px;
	width: 32px;
}

.UserIcon_icon__3Kn8X:hover {
	cursor: pointer;
}

.UserIcon_avatarButton__OJiGl {
	width: 60px;
	height: 60px;
	background: transparent;
	margin: 7px auto auto auto;
	outline: none;
	border: none;
	overflow: hidden;
	display: flex;
}

.UserIcon_avatar__1IH38 {
	width: 50px;
	height: 50px;
	border-radius: 25px;
}

.UserIcon_menu__1b5ce {
	position: fixed;
	top: 65px;
	right: 20px;
	width: 150px;
	z-index: 100;
	display: flex;
	flex-direction: column;
	background-color: #fff;
	box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.1);
	padding: 10px;
}

@media only screen and (max-width: 767px) {
	.UserIcon_menu__1b5ce {
		top: 65px;
		right: 3px;
	}
	
	.UserIcon_avatarButton__OJiGl {
		margin: auto 7px auto auto;
	}
	.UserIcon_wrapperClass__2Z05i {
		height: 60px;
	}
}


.UserIcon_menu__1b5ce button {
	width: 100%;
	height: 30px;
	background-color: transparent;
	border: none;
	text-align: left;

	font-weight: bold;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.075px;
	color: #1b1f24;
	cursor: pointer;
}

.UserIcon_menu__1b5ce button:hover {
	color: #ccc;
}

.UserIcon_inputSearch__3LjJP {
	width: 100%;
	height: 100%;
	border: none;
	font-size: 24px;
	color: #1b1f24;
	opacity: 0.25;
	padding-left: 15px;
}
.UserIcon_inputSearch__3LjJP:focus {
	outline: none;
}

.UserIcon_form__27NgX {
	width: 98%;
	margin: 0 auto;
}

.UserIcon_formTop__2-I_q {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
}

.UserIcon_formTop__2-I_q > button {
	background-color: transparent;
	font-size: 25px;
	border: none;
	cursor: pointer;
}

.NotFound_container__1e_Lt {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.NotFound_button__31YMD {
	text-decoration: none;
	margin: 30px 0;
	background-color: var(--color-yellow);
	padding: 10px 20px;
	border-radius: 5px;
	color: #fff;
	-webkit-transform: all 0.3s ease-in;
	        transform: all 0.3s ease-in;
}

.NotFound_button__31YMD:hover {
	background-color: #f7b500b5;
}

.Filter_filterContainer__2FP5n {
	display: flex;
	align-items: center;
}

.Filter_filterContainerSm__21a8E {
	width: 90%;
	margin: 0 auto;
	margin-top: 30px;
}

.Filter_filterContainer__2FP5n > h3 {
	font-weight: 600;
	font-size: 14px;
	letter-spacing: 0.075px;
	text-transform: uppercase;
	color: #1b1f24;
	margin-right: 10px;
}

.Filter_filterContainerSm__21a8E > h3 {
	width: 25%;
}

.Filter_dropDownContainer__3XMKw {
	width: 150px;
	height: 40px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 10px;
	background: #ffffff;

	border-radius: 4px;
	transition: all 0.2s ease-in-out;
}

.Filter_dropDownContainerSm__2lINr {
	width: 75%;
}

.Filter_dropDownContainerInactive__2XVnz {
	border: 1px solid #000000;
}

.Filter_dropDownContainerActive__3ovnX {
	box-shadow: 2px -11px 19px rgba(0, 0, 0, 0.1);
}

.Filter_dropDownButton__BIk-s {
	border: 0;
	background-color: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	height: 100%;
	width: 20px;
	margin-left: 5px;
	outline: none;
}

.Filter_dropDownIcon__ABa09 {
	height: 12px;
	width: 12px;
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
	-webkit-transform: all 0.2s ease-in-out;
	        transform: all 0.2s ease-in-out;
}

.Filter_dropDownIconRotate__3V66U {
	-webkit-transform: rotate(0deg);
	        transform: rotate(0deg);
}

.Filter_dropDownContainer__3XMKw > span {
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.0875px;
	color: #1b1f24;
}

.Filter_dropDownList__3LOYD {
	margin: 0 -10px;
	position: absolute;
	top: 40px;
	width: 150px;
	height: auto;
	z-index: 5000000;
	background: #ffffff;
	box-shadow: 2px 16px 19px rgba(0, 0, 0, 0.1);
}

.Filter_dropDownItem__3odEa {
	height: 40px;
	border-top: 1px solid #ebe9e6;
	display: flex;
	align-items: center;

	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.0875px;
	color: #1b1f24;
	padding: 0 10px;
	cursor: pointer;
}

.Filter_dropDownItem__3odEa:hover {
	background-color: #eee;
}

@media only screen and (max-width: 767px) {
	.Filter_dropDownList__3LOYD {
		top: 100px;
		width:60% ;
	}
	
}
.Story_storyContainer__2ks5I {
	width: 100%;
	max-width: 350px;
	margin: 15px auto;
}

.Story_imgContainer__2efJ2 {
	height: 400px;
	width: 100%;
	overflow: hidden;
	border-radius: 10px;
	margin-bottom: 16px;
	position: relative;
}

.Story_overlayImgContainer__2jZ2A {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 10;
	background-color: rgba(0, 0, 0, 0.5);
}

.Story_img__2ueBE {
	width: 100%;
	height: 100%;
	object-fit: fill;
}

.Story_profileContainer__2cuFq {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: flex;
	padding: 16px 27px;
	z-index: 50;
}

.Story_profileContainer__2cuFq > img {
	width: 50px;
	height: 50px;
	border-radius: 25px;
}

.Story_userDetail__krqnc {
	display: flex;
	flex-direction: column;
	margin-left: 10px;
}

.Story_userDetail__krqnc > h5 {
	font-size: 12px;
	color: white;
	margin-bottom: 0px;
	font-weight: normal;
	line-height: 20px;
}

.Story_userDetail__krqnc > a {
	font-size: 12px;
	color: white;
	margin-bottom: 0px;
	font-weight: normal;
	line-height: 20px;
	text-decoration: none;
}

.Story_userDetail__krqnc > .Story_userName__2yiQl {
	font-weight: bold;
}

.Story_storyDetail__2fQuX {
	display: flex;
	align-items: flex-start;
}

.Story_viewedButton__31JZ9 {
	display: flex;
	align-items: flex-start;
	margin-bottom: 10px;
}

.Story_viewedButtonImg__qj5Kh {
	width: 90px;
}

.Story_storyLabel__m1xbz
{
	height: 40px;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
}

.Story_storyText__1aIp4 {
	display: flex;
	height: 70px;
	flex-direction: column;
}

.Story_ml5__2wMw_
{
	margin-left: 5px;
}

.Story_storyText__1aIp4 > label {
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.1px;
	color: #1b1f24;
	margin-right: 8px;
	overflow-wrap: break-word;
}

.Story_storyText__1aIp4 > label > span {
	font-weight: normal;
}

.Story_storyText__1aIp4 > span {
	opacity: 0.25;
	text-align: left;
}

.Story_detailLink__3XFTs {
	border-radius: 10px;
	height: 57px;
	margin-left: auto;
	font-size: 16px;
	text-transform: uppercase;
	min-width: 154px;
	border: 1px solid #f7b500;
	background-color: #ffffff;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	color: #000;
	font-weight: lighter;
}

.Story_detailLink__3XFTs:hover {
	outline: none;
	background-color: #f7b500;
	border: 1px solid #ffffff;
	color: #ffffff;
}

.Story_viewedLinkYellow__2ntIC {
	border-radius: 10px;
	height: 40px;
	font-size: 14px;
	text-transform: uppercase;
	width: 90px;
	border: 1px solid #f7b500;
	border-radius: 28px;
	background-color: #ffffff;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	color: #f7b500;
	font-weight: bold;
}

.Story_detailLinkBlue__nsud- {
	border-radius: 10px;
	height: 40px;
	font-size: 14px;
	text-transform: uppercase;
	width: 90px;
	border: 1px solid #2196F3;
	border-radius: 28px;
	background-color: #ffffff;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	color: #2196F3;
	font-weight: bold;
	margin-right: 4px;
}
.Story_dateText__1_IYA
{
	white-space: nowrap;
	text-overflow: ellipsis;
}


.Pagination_pagination__1JgoP {
    display: flex;
    float: right;
}

.Pagination_pagination__1JgoP>li {
    padding: 0 8px;
}

.Pagination_pagination__1JgoP>li>a {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.075px;
    color: #000000;
    text-decoration: none;
}
.Pagination_pagination__1JgoP>li:hover {
    text-decoration: underline;
    cursor: pointer;
}
.Pagination_activeItem__sX_IR{
    color: #F7B500;
    font-weight: 600;
}
.Pagination_nextArrowIcon__2uY78{
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}
.Spinner_container__lrj1S {
	display: flex;
	justify-content: center;
	align-items: center;
}

.Spinner_spinner__2_Kv4 {
	width: 80px;
	height: 80px;
}

.SortDataList_sortData__3GQrG {
	float: right;
	margin-top: 18px;
}

.SortDataList_sortData__3GQrG > li {
	display: inline;
	padding-left: 24px;
	letter-spacing: 0.1px;
	color: #1b1f24;
}

.SortDataList_sortData__3GQrG > li:hover {
	text-decoration: underline;
	cursor: pointer;
}

.SortDataList_selectedData__3C-Qq {
	font-weight: bold;
}

/*  */

.SortDataList_sortDataMd__1WZJx {
	float: right;
	margin-top: 18px;
}

.SortDataList_sortDataMd__1WZJx > li {
	display: inline;
	padding-left: 24px;
	letter-spacing: 0.1px;
	color: #1b1f24;
}

.SortDataList_sortDataMd__1WZJx > li:hover {
	text-decoration: underline;
	cursor: pointer;
}

/*  */

.SortDataList_sortDataSm__HpvXf {
	margin-top: 10px;
	display: flex;
	justify-content: space-around;
}

.SortDataList_sortDataSm__HpvXf > li {
	letter-spacing: 0.1px;
	color: #1b1f24;
}

.SortDataList_sortDataSm__HpvXf > li:hover {
	text-decoration: underline;
	cursor: pointer;
}

@media only screen and (max-width: 600px) {
	.SortDataList_fontSize__mgVL8 {
		font-size: 16px !important;
	}
}

@media only screen and (max-width: 768px) {
	.SortDataList_fontSize__mgVL8 {
		font-size: 10px;
		font-weight: bold;
	}
}

.AdminTable_horizontalScroll__1p2HI {
	overflow-x: auto;
}

.AdminTable_userData__3fi_X {
	width: 100%;
	/* margin-top: 10px; */
	margin-bottom: 30px;
}

.AdminTable_userData__3fi_X thead {
	text-align: left;
	font-size: 12px;
	line-height: 15px;
}

.AdminTable_userData__3fi_X thead tr th {
	padding: 30px 0;
}

.AdminTable_userData__3fi_X tbody td {
	padding: 16px 0;
	font-size: 16px;
	font-weight: 500;
}

.AdminTable_userInfo__10RYA {
	display: flex;
	align-items: center;
	min-width: 250px;
	word-break: break-all;
}

.AdminTable_userImage__pcUuh {
	height: 48px;
	width: 48px;
	border-radius: 24px;
	object-fit: fill;
}

.AdminTable_userName__3nHgd {
	margin-left: 15px;
	font-weight: bold;
}

.AdminTable_arrowIcon__2_mlY {
	height: 12px;
	padding-left: 8px;
}

.AdminTable_select__1ZXp2 {
	border: none;
	padding: 8px;
	background-color: transparent;
	cursor: pointer;

	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.1px;
	color: #1b1f24;
}

.AdminTable_editButton__3tEVI {
	white-space: nowrap;
	cursor: pointer;
}

.AdminTable_editButton__3tEVI img {
	width: 16px;
	height: 16px;
	margin-right: 10px;
}
.AdminTable_selectRed__Z3XV6 {
	color: red;
}

@media only screen and (max-width: 600px) {
	.AdminTable_select__1ZXp2 {
		font-size: 10px;
	}
}

.MobileHeader_container__31Nxg {
	position: relative;
	width: 100%;
	height: 60px;
	overflow: hidden;
	margin-left: 15px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.MobileHeader_logo__1zmpc {
	width: 80px;
	height: 27px;
	margin: auto;
}

.MobileHeader_menu__3oZwq {
	position: absolute;
	height: 40px;
	margin-left: 5px;
	width: 40px;
	background-color: transparent;
	border: 0;
	outline: none;
}

.MobileHeader_overlay__3QO8t {
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.3);
	width: 100%;
	height: 100%;
	z-index: 5000000;
	overflow: hidden;
}

.MobileHeader_drawer__1Im1r {
	width: 280px;
	height: 100%;
	background-color: #fff;
}

.MobileHeader_menuLogo__2yUJe {
	margin-top: 50px;
	margin-left: 50px;
	width: 80px;
	height: 30px;
}

.MobileHeader_menuList__3eCXS {
	margin-top: 100px;
	list-style-type: none;
}

.MobileHeader_menuListItem__1Yv14 {
	padding-left: 40px;
	display: flex;
	align-items: center;
	flex-direction: row;
	/* margin-bottom: 60px; */
	height: 80px;
	width: 100%;
}

.MobileHeader_menuListItem__1Yv14 > img {
	width: 32px;
	height: 32px;
}

.MobileHeader_menuListItem__1Yv14 > a {
	margin-left: 12px;
	text-decoration: none;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.1px;
	color: #1b1f24;
}

.MobileHeader_active__1xC5t {
	background-color: #f3f3f3;
}

.Select_option__3Ykpu {
	height: 40px;
	width: 60px;
	border: none;
	outline: none;
	background-color: transparent;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.Select_optionSvg__1W1Vt {
	align-self: center;
}

.Select_optionModal__2tcFg {
	position: absolute;
	top: 0px;
	right: 15px;
	width: 150px;
	height: 60px;
	background-color: #fff;
	box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.1);
	border: none;
	cursor: pointer;
	outline: none;
	list-style: none;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
}

.Select_optionModal__2tcFg li {
	font-weight: bold;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.075px;
	color: #1b1f24;
	transition: all 0.1s ease-in-out;
}

.Select_optionModal__2tcFg li:hover {
	color: #1b1f24bb;
}

.Select_modalText__2U-CO {
	text-align: center;
	margin-top: 80px;
	margin-left: 60px;
	margin-right: 60px;
}

.Select_buttons__3pxW4 {
	margin-top: 20px;
	margin-left: 50px;
	margin-right: 50px;
	display: flex;
	flex-direction: row;
}

.Select_button__27Ev1 {
	height: 40px !important;
	width: 160px !important;
	margin: 0 10px;
	color: black;
}

.UserTable_horizontalScroll__1KXNT {
	overflow-x: auto;
}

.UserTable_horizontalScroll__1KXNT::-webkit-scrollbar {
	display: none;
}

.UserTable_userData__1-I-3 {
	width: 100%;
	/* margin-top: 10px; */
	margin-bottom: 30px;
}

.UserTable_userData__1-I-3 thead {
	text-align: left;
	font-size: 12px;
	line-height: 15px;
}

.UserTable_userData__1-I-3 thead tr th {
	padding: 30px 16px 30px 0px;
}

.UserTable_userData__1-I-3 tbody td {
	padding: 16px 16px 16px 0px;
	font-size: 16px;
	font-weight: 500;
}

.UserTable_userInfo__1uP3q {
	display: flex;
	align-items: center;
	min-width: 250px;
	word-break: break-all;
}

.UserTable_userImage__1iznt {
	height: 48px;
	width: 48px;
	border-radius: 24px;
	object-fit: fill;
}

.UserTable_userName__3Mlzq {
	padding-left: 15px;
	font-weight: bold;
}

.UserTable_userName__3Mlzq a {
	text-decoration: none;
	color: inherit;
}

.UserTable_userName__3Mlzq a:hover {
	color: #000000ab;
}

.UserTable_arrowIcon__3RpTp {
	height: 12px;
	padding-left: 8px;
}

.UserTable_select__3TkvK {
	border: none;
	padding: 8px;
	background-color: transparent;
	cursor: pointer;

	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.1px;
	color: #1b1f24;
}

.UserTable_selectBlack__1pYuY {
	color: #1b1f24;
}

.UserTable_selectRed__39vhP {
	color: red;
}

.UserTable_editButton__2miGy {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.UserTable_editButton__2miGy img {
	width: 16px;
	height: 16px;
	margin-right: 10px;
}

.UserTable_textDecorationNone__2sIie {
	text-decoration: none;
}


@media only screen and (max-width: 600px) {
	.UserTable_select__3TkvK {
		font-size: 10px;
	}
}

.Comment_commentContainer__1O00n {
	background: #ffffff;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
	border-radius: 10px;
	padding: 0 10px;
	margin-bottom: 22px;
}

.Comment_header__1u98_ {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.Comment_header__1u98_ img {
	width: 48px;
	height: 48px;
	border-radius: 24px;
	margin-right: 10px;
	overflow: hidden;
}

.Comment_options__2hpE5 {
	margin-left: auto;
	margin-right: 10px;
	display: flex;
}

.Comment_optionCenter__1S7kd {
	margin-left: auto;
	margin-right: auto;
	justify-content: space-around;
}

.Comment_options__2hpE5 > button {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: transparent;
	border: none;
	margin: 0 5px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
}

.Comment_options__2hpE5 > button:hover {
	color: red;
}

.Comment_options__2hpE5 > button img {
	width: 20px;
	height: 20px;
	margin-right: 5px;
}

.Comment_content__x5LpU {
	width: 96%;
	margin: 0 auto;
}

.Comment_reportButton__1-KhG {
	width: 170px;
	height: 32px;
	background-color: #f7b5005d !important;
	border-radius: 43px;
	border: 0;
	color: #faa61a;
	font-weight: bold;
}

.Comment_hideBtn__olB6Z
{
	width: 100px;
	float: right;
	padding-top: 5px;
}

.Comment_textDecorationNone__1s3sv
{
	text-decoration: none;
}
.ContributionTable_horizontalScroll__1EoMs {
	overflow-x: auto;
}

.ContributionTable_userData__wF7zU {
	width: 100%;
	/* margin-top: 10px; */
	margin-bottom: 30px;
}

.ContributionTable_userData__wF7zU thead {
	text-align: left;
	font-size: 12px;
	line-height: 15px;
}

.ContributionTable_userData__wF7zU thead tr th {
	padding: 30px 0;
}

.ContributionTable_userData__wF7zU tbody td {
	padding: 16px 0;
	font-size: 16px;
	font-weight: 500;
}

.ContributionTable_userInfo__3esNF {
	display: flex;
	align-items: center;
	min-width: 250px;
	word-break: break-all;
}

.ContributionTable_userImage__2Wull {
	height: 48px;
	width: 48px;
	border-radius: 24px;
	object-fit: fill;
}

.ContributionTable_userName__XBVQ0 {
	margin-left: 15px;
	font-weight: bold;
}

.ContributionTable_arrowIcon__3-hOx {
	height: 12px;
	padding-left: 8px;
}

.ContributionTable_select__1qbiP {
	border: none;
	padding: 8px;
	background-color: transparent;
	cursor: pointer;

	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.1px;
	color: #1b1f24;
}

.ContributionTable_editButton__1WerU {
	white-space: nowrap;
	cursor: pointer;
}

.ContributionTable_editButton__1WerU img {
	width: 16px;
	height: 16px;
	margin-right: 10px;
}
.ContributionTable_selectRed__3f_W0 {
	color: red;
}

@media only screen and (max-width: 600px) {
	.ContributionTable_select__1qbiP {
		font-size: 10px;
	}
}

.auth_form__29LDK {
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	height: 100vh;
	margin: 0 25px;
}

.auth_spacingTopBtm__pjWqi {
	margin-top: 8px;
	margin-bottom: 30px;
}

.auth_passwordLabel__9thAM {
	align-self: flex-end;
	margin-bottom: 20px;
	margin-top: -20px;
	font-size: 12px;
	text-decoration: none;
}

.auth_mainPara__16TfA {
	display: flex;
}

.auth_textDecorationNone__1XbJx
{
	text-decoration: none;
}

.auth_mainBlock__3XoH2
{
	background-color: #000;
}
.auth_inputBoarder__p93zU
{
	border-radius: 3px !important;
}

.auth_forgotPassLabel__ctVnr
{
	color: #d39c05;
    font-size: 16px;
    font-weight: 600;
	margin-top: -15px;
    text-decoration: none;
    align-self: flex-end;
}
.auth_titleHeader__Viyxo h1
{
	color: #fff !important;
}

.auth_logoHeader__3S53j
{
	width: 123px;
    margin-bottom: 22px;
}
.auth_leftContainer__2w4RQ
{
	padding-left: 60px !important;
	padding-right: 60px !important;
}

.auth_loginButton__3hFSn
{
	width: 40px;
	background-color: Transparent;
    background-repeat:no-repeat;
    border: none;
    cursor:pointer;
    overflow: hidden;
    outline:none;
}
.auth_spinner__18vlM {
	width: 48px;
	height: 48px;
}
.auth_labelColor__2SI7u
{
	color:#fff !important;
}
.Logo_logo__272xe {
	width: 125px;
	height: 42px;
	margin-bottom: 50px;
}

.Media_imgContainer__27mGV {
	height: 500px;
	margin-left: 10px;
	border-radius: 10px;
	overflow: hidden;
	margin-bottom: 20px;
	border-radius: 10px;
}

.Media_img__1Z0oc {
	width: 100%;
	height: 100%;
	object-fit: fill;
	border-radius: 10px;
}

.UserSection_userSection__f6WwO {
	width: 100%;
	min-height: 80px;
	display: flex;
	margin-bottom: 15px;
}

.UserSection_userAvatar__3Hi3g {
	align-self: center;
	height: 80px;
	width: 80px;
	border-radius: 40px;
	object-fit: contain;
}

.UserSection_userDetail__BVOMu {
	height: 100%;
	width: 100%;
	padding-top: 10px;
	margin-left: 20px;
}

.UserSection_userHeadingContainer__31Xom {
	display: flex;
	align-items: center;
}
.UserSection_userHeading__3D1iG {
	font-family: sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.1px;
	color: #1b1f24;
	margin-right: 10px;
}

.UserSection_userHeadingContainer__31Xom > span {
	font-weight: 500;
	font-size: 10px;
	color: #1b1f24;
}

.UserSection_locationText__1rc7E {
	margin-top: 10px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.1px;
	mix-blend-mode: normal;
	margin-bottom: auto;
	color: #2f80ed;
}

.UserSection_locationText__1rc7E a {
	text-decoration: none;
	cursor: pointer;
	color: inherit;
}

.UserSection_locationText__1rc7E a:hover {
	color: #2f81eddc;
	text-decoration: underline;
}

.UserSection_userOption__1AEct {
	height: 40px;
	width: 60px;
	border: none;
	background-color: transparent;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.UserSection_optionSvg__2oykW {
	align-self: center;
}

.UserSection_optionModal__3K3Yt {
	position: absolute;
	top: 0px;
	right: 15px;
	width: 150px;
	height: 60px;
	background-color: #fff;
	box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.1);
	border: none;
	cursor: pointer;
	outline: none;
	list-style: none;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
}

.UserSection_optionModal__3K3Yt li {
	font-weight: bold;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.075px;
	color: #1b1f24;
	transition: all 0.1s ease-in-out;
}

.UserSection_optionModal__3K3Yt li:hover {
	color: #1b1f24bb;
}
.UserSection_textDecorationNone__3Uv-I {
	text-decoration: none;
}

.DetailSection_storyHeading__3pJ3e {
	font-family: Montserrat;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	line-height: 29px;
	letter-spacing: 0.15px;
	overflow-wrap: break-word;

	color: #1b1f24;
}

.DetailSection_storyDetail__aOMbm {
	margin-top: 10px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 20px;
	letter-spacing: 0.075px;
	color: #1b1f24;
	mix-blend-mode: normal;
	overflow-wrap: break-word;

	opacity: 0.5;
}

.ReactionSection_reactionContainer__2gKNx {
	margin-top: 15px;
	width: 95%;
	margin: 0 auto;
	height: 35px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
	padding-bottom: 8px;
	border-bottom: 1px solid #979797;
}

.ReactionSection_likeContainer__1zW5T {
	display: flex;
	align-items: center;
	justify-content: center;
}
.ReactionSection_reactionTextContainer__WFxvp {
	display: flex;
}

.ReactionSection_reactionText__2rhUU {
	font-family: Montserrat;
	font-style: normal;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.075px;
	color: #1b1f24;
	margin: 0px 10px;
}

.CommentList_commentListContainer__3uK9s {
	width: 100%;
	max-height: 400px;
	min-height: 300px;
}

.CommentList_commentContainer__sRrVv {
	width: 95%;
	min-height: 80px;
	margin: 8px auto 15px;
	display: flex;
	padding-bottom: 20px;
}

.CommentList_commentAvatar__qxIn5 {
	width: 32px;
	height: 100%;
}

.CommentList_commentAvatar__qxIn5 {
	height: 48px;
	width: 48px;
	border-radius: 24px;
}

.CommentList_commentContent__1JgHT {
	width: 100%;
	height: 100%;
	margin-left: 10px;
	margin-top: 10px;
}

.CommentList_commentContentTop__13zf5 {
	display: flex;
	align-items: center;
}

.CommentList_commentContentTop__13zf5 h2 {
	font-family: Montserrat;
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.075px;
	color: #1b1f24;
}

.CommentList_commentContentTop__13zf5 span {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 12px;
	letter-spacing: 0.0625px;
	color: #1b1f24;
	margin-left: 10px;
}

.CommentList_commentContent__1JgHT p {
	margin-top: 10px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 13px;
	overflow: hidden;
	letter-spacing: 0.06875px;
	color: #1b1f24;
	margin-bottom: 8px;
	word-break: break-all;
}

.CommentList_commentContent__1JgHT span {
	font-family: Montserrat;
	font-style: normal;
	font-size: 11px;
	line-height: 13px;
	letter-spacing: 0.06875px;
	color: #1b1f24;
}

.CommentList_commentBorder__163VP {
	border-bottom: 1px solid #ccc;
	margin-top: 10px;
}

.CommentList_commentOption__1PnaB {
	width: 20px;
	height: 100%;
	margin-top: 10px;
	background-color: transparent;
	border: none;
	cursor: pointer;
	outline: none;
	-webkit-transform: all 0.3s ease-in;
	        transform: all 0.3s ease-in;
}

.CommentList_commentOptionSvg__3EIkJ {
	height: 16px;
	width: 16px;
}

.CommentList_scrollBarStyle__kZpq1::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	background-color: #ededed;
}

.CommentList_scrollBarStyle__kZpq1::-webkit-scrollbar {
	width: 8px;
	background-color: #ededed;
}

.CommentList_scrollBarStyle__kZpq1::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
	background-color: #7f7f7f;
}

.CommentList_textDecorationNone__3SoK7
{
	text-decoration: none;
}

.CommentList_commentEmptyContainer__3neGn
{
	width: 100%;
	text-align: center;
	margin-top: 143px;
}
.VerificationList_verificationContainer__3DGxc {
	min-height: 200px !important;
	margin: 10px auto;
	padding: 10px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
	border-radius: 10px;
}

.VerificationList_verification__17hFR {
	width: 100%;
	height: 100%;
}

.VerificationList_verificationTop__3-2Il {
	display: flex;
	align-items: center;
}

.VerificationList_verificationTop__3-2Il img {
	overflow: hidden;
	width: 48px;
	height: 48px;
	border-radius: 24px;
	margin-right: 8px;
}

.VerificationList_verificationTop__3-2Il h2 {
	font-family: Montserrat;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.1px;
	color: #1b1f24;
}

.VerificationList_verificationImage__1LN7v {
	height: 160px;
	width: 132px;
	overflow: hidden;
	border-radius: 10px;
}

.VerificationList_verificationImage__1LN7v img {
	height: 160px;
	width: 132px;
	object-fit: fill;
}

.VerificationList_verificationPara__1aCDw {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.1px;
	color: #1b1f24;
	mix-blend-mode: normal;
	opacity: 0.5;
	margin-top: 15px;
	word-break: break-all;
}
.VerificationList_textDecorationNone__k971C
{
	text-decoration: none;
}

.VerificationList_userHeadingContainer__19Ywv {
	display: flex;
	align-items: center;
}

.VerificationList_locationText__2tBaO {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.1px;
	mix-blend-mode: normal;
	margin-bottom: auto;
	color: #2f80ed;
}

.VerificationList_locationText__2tBaO a {
	text-decoration: none;
	cursor: pointer;
	color: inherit;
}

.VerificationList_locationText__2tBaO a:hover {
	color: #2f81eddc;
	text-decoration: underline;
}

.VerificationList_imgUp__2c42B
{
	display: none;
}

@media only screen and (max-width: 767px) {
	.VerificationList_verificationContainer__3DGxc
	{
		display:unset;
	}
	.VerificationList_imgDown__2jFNy
	{
		display: none;	
	}
	.VerificationList_imgUp__2c42B
	{
		display: unset;
	}

	.VerificationList_VerificationIMG__3iOHM
	{
		border-radius: 7px;
		width: 100% !important;
		object-fit: cover !important;
	}
	.VerificationList_verification__17hFR {
		margin-top: 20px;
		height: unset;
	}
	.VerificationList_verificationImage__1LN7v
	{
		width: 100%;
	}
}
.Detail_topSection__2swEg {
	margin-bottom: 20px;
}

.Detail_linkContainer__1c1hK {
	text-decoration: none;
	color: #000;
	font-weight: bold;
	display: flex;
	align-items: center;
	background-color: transparent;
	border: none;
	cursor: pointer;
	outline: none;
}

.Detail_backSvg__2Tkl9 {
	margin-right: 10px;
	height: 14px;
	width: 14px;
}

.Detail_infoContainer__2LdXU {
	min-height: 500px;
	margin-left: auto;
}

.Detail_bottomSection__HRC4E {
	padding: 10px;
	margin-bottom: 20px;
}

.Detail_emptyVerification__12DuW {
	width: 100%;
}
.Detail_verifyEmptyContainer__22UWC
{
	width: 100%;
	text-align: center;
	margin: 100px 0px;
}
.Stories_searchCriteria__2uViX {
	margin-bottom: 13px;
	margin-left: 10px !important;
}

.Stories_sortContainer__3XDVr {
	margin: 0 auto;
}

.Stories_sortDataSm__3YLMt {
	margin-top: 10px;
	display: flex;
	justify-content: space-around;
}

.Stories_sortDataSm__3YLMt > li {
	letter-spacing: 0.1px;
	color: #1b1f24;
}

.Stories_sortDataSm__3YLMt > li:hover {
	text-decoration: underline;
	cursor: pointer;
}

.Stories_extraSpace__2kIkw {
	height: auto;
	height: 450px;
}

/*  */

.Stories_sortDataMd__qv5xX {
	float: right;
	margin-top: 10px;
}

.Stories_sortDataMd__qv5xX > li {
	display: inline;
	padding-left: 24px;
	letter-spacing: 0.1px;
	color: #1b1f24;
}

.Stories_sortDataMd__qv5xX > li:hover {
	text-decoration: underline;
	cursor: pointer;
}

.Stories_selectedData__2gs-K {
	font-weight: bold;
}

.Stories_marginBtm__3kwDZ {
	margin-bottom: 100px;
}

.Stories_positionInitial__1Ycey {
	position: static !important;
	position: initial !important;
}
.Admins_overlay__2o419 {
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.8);
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Admins_card__gitnM {
	width: 484px;
	min-height: 430px;
	background-color: #fff;
	padding: 10px;
	border-radius: 20px;
	overflow: hidden;
}

.Admins_button__2H-0C {
	color: #000;
}

@media only screen and (max-width: 600px) {
	.Admins_button__2H-0C {
		width: 126px;
	}
  }

.Admins_fileInput__2peXI::-webkit-file-upload-button {
	padding: 10px;
	background-color: #f7b500;
	border: none;
	border-radius: 10px;
	color: #eee;
	cursor: pointer;
}

.Admins_fileInput__2peXI::-webkit-file-upload-button:hover {
	box-shadow: 1px 3px 1px rgba(0, 0, 0, 0.3);
}

.Admins_bottomModal__3C7O6 {
	display: flex;
	align-items: center;
}

.Admins_headingCenter__3GZcc {
	display: flex;
	align-items: center;
}

.Admins_modalCross__zpoah
{
	float: right;
    padding: 5px;
    text-decoration: none;
    color: #665a5a;
    font-weight: 600;
}


.Admins_modalTitle__a3Qgo
{
	float: left;
}

.Header_reportTabs__36M1Q {
	overflow-x: auto;
	margin: 50px 0 20px 0;
}

.Header_reportTabs__36M1Q > li {
	display: inline;
	padding-right: 24px;
	font-weight: bold;
	font-size: 24px;
	line-height: 29px;
	color: #1b1f24;
	opacity: 0.25;
	cursor: pointer;
}

.Header_reportTabs__36M1Q > li > a {
	text-decoration: none;
	color: inherit;
}

.Header_reportTabs__36M1Q > li.Header_activeTab__3PEsc {
	opacity: 1;
}

@media only screen and (max-width: 600px) {
	.Header_reportTabs__36M1Q > li {
		padding-right: 16px;
	}
}

.User_topSection__2Zi8P {
	margin-top: 50px;
	margin-bottom: 20px;
}

.User_linkContainer__1Khp0 {
	text-decoration: none;
	color: #000;
	font-weight: bold;
	display: flex;
	height: 100%;
	align-items: center;
	background-color: transparent;
	border: none;
	cursor: pointer;
	outline: none;
}

.User_backSvg__R2f_0 {
	margin-right: 10px;
	height: 14px;
	width: 14px;
}

.User_button__3rX_v {
	width: 130px;
	height: 40px;
	margin-top: auto;
	margin-bottom: auto;
	color: #1b1f24;
	font-weight: bold;
}

.User_selectContainer__2c3b4 {
	margin-left: auto;
	width: 100px;
	height: 65px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.User_profilePicture__1BzDh {
	width: 200px;
	height: 200px;
	border-radius: 100px;
	margin-left: 10px;
}
.User_marginZero__3iuit
{
	margin-left: 0px !important;
	margin-right: 0px !important;
}
.User_profileDetail__12d7_ {
	flex: none !important;
	float: right;
    width: 78% !important;
    max-width: 78% !important;
    margin-left: 25px !important;
}

.User_heading__zg2kE {
	margin-top: 20px;
}

.User_para__3uK3o {
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.1px;
	color: #1b1f24;
	overflow: hidden;
	margin: 10px 0;
}

.User_paraStrong__1DF9A {
	margin-top: 25px;
}

.User_reportContainer__38Xvy {
	height: 55px;
	width: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 15px;
}

.User_reportLeft__1WkBK {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.User_reportLeft__1WkBK img {
	margin-right: 15px;
	width: 48px;
	height: 48px;
	border-radius: 24px;
}

.User_reportPara__27IlN {
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.1px;
	color: #1b1f24;
	text-decoration: none;
}

.User_reportRight__37s_b {
	width: 35%;
}

.User_heading__zg2kE {
	font-size: 40px;
	font-weight: bold;
}
.User_optionsSec__1qJSb {
	float: right;
}

.User_options__1nwsR {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	height: 80px;
}
.User_marginTop20__1d2AP
{
	margin-top: 20px;
}
@media only screen and (max-width: 1000px) {
	.User_topSection__2Zi8P {
		margin-top: 0px;
		margin-bottom: 0px;
	}

	@media only screen and (max-width: 1000px) {
		.User_profileDetail__12d7_ {
			float: none;
		}

		.User_profilePicture__1BzDh {
			width: 160px;
			height: 160px;
			border-radius: 100px;
			margin-left: 0px;
		}
	}
	@media only screen and (max-width: 1500px) {
		.User_profileDetail__12d7_ {
			flex: none !important;
			width: 70% !important;
			max-width: 70% !important;
			margin-left: 0px !important;
			padding: 0px 10px;
		}
		.User_linkContainer__1Khp0 {
			padding-left: 10px;
		}
	}

	@media only screen and (max-width: 600px) {
		.User_profileDetail__12d7_ {
			flex: none !important;
			width: 100% !important;
			max-width: 100% !important;
		}
		.User_profilePicture__1BzDh {
			width: 120px;
			height: 120px;
			border-radius: 100px;
			margin-left: 0px;
		}

		.User_heading__zg2kE {
			font-size: 22px;
			font-weight: 600;
		}
	}
}
.UserSection_userSection__6jmd6 {
	width: 100%;
	min-height: 80px;
	display: flex;
}

.UserSection_userAvatar__3lbzW {
	align-self: center;
	height: 60px;
	width: 60px;
	border-radius: 50%;
	object-fit: fill;
}

.UserSection_userDetail__2kS_N {
	height: 100%;
	width: 100%;
	padding-top: 10px;
	margin-left: 20px;
}

.UserSection_userHeadingContainer__3bzL9 {
	display: flex;
	align-items: center;
}
.UserSection_userHeading__3_uT9 {
	font-family: Montserrat;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.1px;
	color: #1b1f24;
	margin-right: 10px;
}

.UserSection_userHeadingContainer__3bzL9 > span {
	font-weight: 500;
	font-size: 10px;
	color: #1b1f24;
}

.UserSection_locationText__GqupM {
	margin-top: 10px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.1px;
	mix-blend-mode: normal;
	margin-bottom: auto;
	color: #2f80ed;
}

.UserSection_locationText__GqupM a {
	text-decoration: none;
	cursor: pointer;
	color: inherit;
}

.UserSection_locationText__GqupM a:hover {
	color: #2f81eddc;
	text-decoration: underline;
}

.UserSection_userOption__22Yyf {
	height: 40px;
	width: 60px;
	border: none;
	background-color: transparent;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.UserSection_optionSvg__cot9L {
	align-self: center;
}

.UserSection_optionModal__24czG {
	position: absolute;
	top: 0px;
	right: 15px;
	width: 150px;
	height: 60px;
	background-color: #fff;
	box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.1);
	border: none;
	cursor: pointer;
	outline: none;
	list-style: none;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
}

.UserSection_optionModal__24czG li {
	font-weight: bold;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.075px;
	color: #1b1f24;
	transition: all 0.1s ease-in-out;
}

.UserSection_optionModal__24czG li:hover {
	color: #1b1f24bb;
}

.UserSection_textDecorationNone__18YVF {
	text-decoration: none;
}

.CommentsLazyLoad_commentListContainer__aNnZ8 {
	width: 100%;
	max-height: 400px;
}

.CommentsLazyLoad_commentContainer__2Avkk {
	width: 95%;
	min-height: 80px;
	margin: 8px auto 15px;
	display: flex;
	padding-bottom: 20px;
}

.CommentsLazyLoad_commentAvatar__3ji9Y {
	width: 32px;
	height: 100%;
}

.CommentsLazyLoad_commentAvatar__3ji9Y {
	height: 48px;
	width: 48px;
	border-radius: 24px;
}

.CommentsLazyLoad_commentContent__3aj26 {
	width: 100%;
	height: 100%;
	margin-left: 10px;
	margin-top: 10px;
}

.CommentsLazyLoad_commentContentTop__2JYRn {
	display: flex;
	align-items: center;
}

.CommentsLazyLoad_commentContentTop__2JYRn h2 {
	font-family: Montserrat;
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.075px;
	color: #1b1f24;
}

.CommentsLazyLoad_commentContentTop__2JYRn span {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 12px;
	letter-spacing: 0.0625px;
	color: #1b1f24;
	margin-left: 10px;
}

.CommentsLazyLoad_commentContent__3aj26 p {
	margin-top: 10px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 13px;
	overflow: hidden;
	letter-spacing: 0.06875px;
	color: #1b1f24;
	margin-bottom: 8px;
}

.CommentsLazyLoad_commentContent__3aj26 span {
	font-family: Montserrat;
	font-style: normal;
	font-size: 11px;
	line-height: 13px;
	letter-spacing: 0.06875px;
	color: #1b1f24;
}

.CommentsLazyLoad_commentBorder__uN4UR {
	border-bottom: 1px solid #979797;
	margin-top: 10px;
}

.CommentsLazyLoad_commentOption__IO84F {
	width: 20px;
	height: 100%;
	margin-top: 10px;
	background-color: transparent;
	border: none;
	cursor: pointer;
	outline: none;
}

.CommentsLazyLoad_commentOptionSvg__3zhi2 {
	height: 16px;
	width: 16px;
}

.CommentsLazyLoad_scrollBarStyle__14LEf::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	background-color: #ededed;
}

.CommentsLazyLoad_scrollBarStyle__14LEf::-webkit-scrollbar {
	width: 8px;
	background-color: #ededed;
}

.CommentsLazyLoad_scrollBarStyle__14LEf::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
	background-color: #7f7f7f;
}
.CommentsLazyLoad_textDecorationNone__2UkaM
{
	text-decoration: none;
}
.CommentsLazyLoad_commentEmptyContainer__4_VJX
{
	width: 100%;
	text-align: center;
	margin-top: 143px;
}
.Story_topSection__3Axrv {
	margin-bottom: 20px;
}

.Story_linkContainer__3VFZu {
	text-decoration: none;
	color: #000;
	font-weight: bold;
	display: flex;
	align-items: center;
	background-color: transparent;
	border: none;
	cursor: pointer;
	outline: none;
}

.Story_backSvg__3Yzyh {
	margin-right: 10px;
	height: 14px;
	width: 14px;
}

.Story_infoContainer__2dfKf {
	min-height: 500px;
	margin-left: auto;
}

.Story_bottomSection__3SoWv {
	padding: 10px;
	margin-bottom: 20px;
}

.Story_reportContainer__c5SzH {
	height: 55px;
	width: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 15px;
}

.Story_reportLeft__2wc-n {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.Story_reportLeft__2wc-n img {
	margin-right: 15px;
	width: 48px;
	height: 48px;
	border-radius: 24px;
}

.Story_reportPara__xFRHM {
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.1px;
	color: #1b1f24;
}

.Story_reportRight__1zi9r {
	width: 35%;
}

.Story_textDecorationNone__2NdFa
{
	text-decoration: none;
}

.Users_textAlignRight__1MaIn {
    text-align: right;
}
.User_options__3hM3m {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	height: 80px;
}
.User_optionsSec__2hVX8 {
	float: right;
}

.User_linkContainer__3TmaX {
	text-decoration: none;
	color: #000;
	font-weight: bold;
	display: flex;
	height: 100%;
	align-items: center;
	background-color: transparent;
	border: none;
	cursor: pointer;
	outline: none;
}

.User_backSvg__34KSb {
	margin-right: 10px;
	height: 14px;
	width: 14px;
}

.User_button__3oiKk {
	width: 130px;
	height: 40px;
	margin-top: auto;
	margin-bottom: auto;
	color: #1b1f24;
	font-weight: bold;
}

.User_selectContainer__21EOB {
	margin-left: auto;
	width: 100px;
	height: 65px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.User_para__3GaD0 {
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.1px;
	color: #1b1f24;
	overflow: hidden;
	margin: 10px 0;
	text-overflow: ellipsis;
}

.User_paraStrong__2uekg {
	margin-top: 25px;
}

.User_reportContainer__1BlDJ {
	height: 55px;
	width: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 15px;
}

.User_reportLeft__2Bd76 {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.User_reportLeft__2Bd76 img {
	margin-right: 15px;
	width: 48px;
	height: 48px;
	border-radius: 24px;
}

.User_reportPara__3_qVw {
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.1px;
	color: #1b1f24;
}

.User_reportRight__2YXw6 {
	width: 35%;
}

.User_dividerLine__1pwc3 {
	background-color: #9089896e;
	height: 1px;
	border: none;
	margin-top: 65px;
}
.User_profilePicture__3VnKR {
	width: 200px;
	height: 200px;
	border-radius: 100px;
	margin-left: 10px;
}

.User_heading__3d6wf {
	font-size: 40px;
	font-weight: bold;
}

.User_marginTop20__210XS {
	margin-top: 20px;
}

.User_profileDetail__H597U {
	flex: none !important;
	float: right;
	width: 78% !important;
	max-width: 78% !important;
	margin-left: 25px !important;
}

.User_topSection__3F7MJ {
	margin-top: 50px;
	margin-bottom: 20px;
}
.User_avatar__1iw6b {
	width: 50px;
	height: 50px;
	border-radius: 25px;
}

@media only screen and (max-width: 1000px) {
	.User_topSection__3F7MJ {
		margin-top: 0px;
		margin-bottom: 10px;
	}

	@media only screen and (max-width: 1000px) {
		.User_profileDetail__H597U {
			float: none;
		}

		.User_profilePicture__3VnKR {
			width: 160px;
			height: 160px;
			border-radius: 100px;
			margin-left: 0px;
		}
	}
	@media only screen and (max-width: 1500px) {
		.User_profileDetail__H597U {
			flex: none !important;
			width: 70% !important;
			max-width: 70% !important;
			margin-left: 0px !important;
			padding: 0px 10px;
		}
		.User_linkContainer__3TmaX {
			padding-left: 10px;
		}
	}

	@media only screen and (max-width: 600px) {
		.User_profileDetail__H597U {
			flex: none !important;
			width: 100% !important;
			max-width: 100% !important;
		}
		.User_profilePicture__3VnKR {
			width: 120px;
			height: 120px;
			border-radius: 100px;
			margin-left: 0px;
		}

		.User_dividerLine__1pwc3 {
			height: 0px;
			border: none;
			margin-top: 0px;
		}

		.User_heading__3d6wf {
			font-size: 22px;
			font-weight: 600;
		}
	}
}
.Contributions_overlay__1_oe5 {
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.8);
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Contributions_card__1dKRb {
	width: 484px;
	min-height: 430px;
	background-color: #fff;
	padding: 10px;
	border-radius: 20px;
	overflow: hidden;
}

.Contributions_button__2H205 {
	color: #000;
}

@media only screen and (max-width: 600px) {
	.Contributions_button__2H205 {
		width: 126px;
	}
  }

.Contributions_fileInput__2p4h3::-webkit-file-upload-button {
	padding: 10px;
	background-color: #f7b500;
	border: none;
	border-radius: 10px;
	color: #eee;
	cursor: pointer;
}

.Contributions_fileInput__2p4h3::-webkit-file-upload-button:hover {
	box-shadow: 1px 3px 1px rgba(0, 0, 0, 0.3);
}

.Contributions_bottomModal__2ThF- {
	display: flex;
	align-items: center;
}

.Contributions_headingCenter__1fZFc {
	display: flex;
	align-items: center;
}

.Contributions_modalCross__vvhxD
{
	float: right;
    padding: 5px;
    text-decoration: none;
    color: #665a5a;
    font-weight: 600;
}


.Contributions_modalTitle__2eNot
{
	float: left;
}

.Contributions_month__1NMsY
{
	width: 49%;
    float: left;
}


.Contributions_year__2Z7cV
{
	width: 49%;
    float: right;
}

.Contributions_dateSelect__15ZiZ
{
	width: 100%;
    background-color: white;
    border-radius: 5px;
	height: 42px;
	padding-left: 15px;
}

.Contributions_marginBottom16__H68bJ
{
	margin-bottom: 16px;
}

.Contributions_label__3ixd7 {
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.075px;
	color: #000;
}
@font-face {
	font-family: 'SF Pro Display';
	src: url(/static/media/SFProDisplay-Regular.ef9c24ac.woff);
}

:root {
	--color-yellow: #f7b500;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	overflow-x: hidden;
	font-family: Montserrat;
	font-style: normal;
}

.top-space {
	margin-top: 90px;
}

@media only screen and (max-width: 768px) {
	.top-space {
		margin-top: 10px;
	}
}

