.imgContainer {
	height: 500px;
	margin-left: 10px;
	border-radius: 10px;
	overflow: hidden;
	margin-bottom: 20px;
	border-radius: 10px;
}

.img {
	width: 100%;
	height: 100%;
	object-fit: fill;
	border-radius: 10px;
}
