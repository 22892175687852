@import url('https://fonts.googleapis.com/css?family=Montserrat:regular,bold,italic&subset=latin,latin-ext');

@font-face {
	font-family: 'SF Pro Display';
	src: url('fonts/SFProDisplay-Regular.woff');
}

:root {
	--color-yellow: #f7b500;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	overflow-x: hidden;
	font-family: Montserrat;
	font-style: normal;
}

.top-space {
	margin-top: 90px;
}

@media only screen and (max-width: 768px) {
	.top-space {
		margin-top: 10px;
	}
}
