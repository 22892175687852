.commentListContainer {
	width: 100%;
	max-height: 400px;
	min-height: 300px;
}

.commentContainer {
	width: 95%;
	min-height: 80px;
	margin: 8px auto 15px;
	display: flex;
	padding-bottom: 20px;
}

.commentAvatar {
	width: 32px;
	height: 100%;
}

.commentAvatar {
	height: 48px;
	width: 48px;
	border-radius: 24px;
}

.commentContent {
	width: 100%;
	height: 100%;
	margin-left: 10px;
	margin-top: 10px;
}

.commentContentTop {
	display: flex;
	align-items: center;
}

.commentContentTop h2 {
	font-family: Montserrat;
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.075px;
	color: #1b1f24;
}

.commentContentTop span {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 12px;
	letter-spacing: 0.0625px;
	color: #1b1f24;
	margin-left: 10px;
}

.commentContent p {
	margin-top: 10px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 13px;
	overflow: hidden;
	letter-spacing: 0.06875px;
	color: #1b1f24;
	margin-bottom: 8px;
	word-break: break-all;
}

.commentContent span {
	font-family: Montserrat;
	font-style: normal;
	font-size: 11px;
	line-height: 13px;
	letter-spacing: 0.06875px;
	color: #1b1f24;
}

.commentBorder {
	border-bottom: 1px solid #ccc;
	margin-top: 10px;
}

.commentOption {
	width: 20px;
	height: 100%;
	margin-top: 10px;
	background-color: transparent;
	border: none;
	cursor: pointer;
	outline: none;
	transform: all 0.3s ease-in;
}

.commentOptionSvg {
	height: 16px;
	width: 16px;
}

.scrollBarStyle::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	background-color: #ededed;
}

.scrollBarStyle::-webkit-scrollbar {
	width: 8px;
	background-color: #ededed;
}

.scrollBarStyle::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
	background-color: #7f7f7f;
}

.textDecorationNone
{
	text-decoration: none;
}

.commentEmptyContainer
{
	width: 100%;
	text-align: center;
	margin-top: 143px;
}