.verificationContainer {
	min-height: 200px !important;
	margin: 10px auto;
	padding: 10px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
	border-radius: 10px;
}

.verification {
	width: 100%;
	height: 100%;
}

.verificationTop {
	display: flex;
	align-items: center;
}

.verificationTop img {
	overflow: hidden;
	width: 48px;
	height: 48px;
	border-radius: 24px;
	margin-right: 8px;
}

.verificationTop h2 {
	font-family: Montserrat;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.1px;
	color: #1b1f24;
}

.verificationImage {
	height: 160px;
	width: 132px;
	overflow: hidden;
	border-radius: 10px;
}

.verificationImage img {
	height: 160px;
	width: 132px;
	object-fit: fill;
}

.verificationPara {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.1px;
	color: #1b1f24;
	mix-blend-mode: normal;
	opacity: 0.5;
	margin-top: 15px;
	word-break: break-all;
}
.textDecorationNone
{
	text-decoration: none;
}

.userHeadingContainer {
	display: flex;
	align-items: center;
}

.locationText {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.1px;
	mix-blend-mode: normal;
	margin-bottom: auto;
	color: #2f80ed;
}

.locationText a {
	text-decoration: none;
	cursor: pointer;
	color: inherit;
}

.locationText a:hover {
	color: #2f81eddc;
	text-decoration: underline;
}

.imgUp
{
	display: none;
}

@media only screen and (max-width: 767px) {
	.verificationContainer
	{
		display:unset;
	}
	.imgDown
	{
		display: none;	
	}
	.imgUp
	{
		display: unset;
	}

	.VerificationIMG
	{
		border-radius: 7px;
		width: 100% !important;
		object-fit: cover !important;
	}
	.verification {
		margin-top: 20px;
		height: unset;
	}
	.verificationImage
	{
		width: 100%;
	}
}